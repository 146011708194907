import React from 'react';
import logo from './logo.svg';
import 'devextreme/dist/css/dx.light.css';
import Button from 'devextreme-react/button';


function App() {
  return (
    <Button
    text="Click me"
/>
  );
}

export default App;
